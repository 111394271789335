'use strict';

$().ready(function(){
    setupSlideMenu();
    $("section").each(function(){
        if ($(this).find(".page-container").length > 1){
            $(this).addClass('has-slider');
            checkSlidePages($(this));
        }
    });
    if ($('form').length){
        $('form').each(function(){
            $(this).addClass('floatlabel-active').validate();
            $(this).find('.js-float-wrap').FloatLabel();
        });
    }
    $("a.logo").css({"opacity": "0", "transition": "none"});
    $('.kmsgallery-thumbnail-image').addClass('lazyload');
    mobileHeaderLogo();
});

// hide logo on splash page
function headerLogo() {
    if (current_section >= 1) {
        $("a.logo").css({"opacity": "1", "transition": "all 3s ease-in-out"});
    } else {
        $("a.logo").css({"opacity": "0", "transition": "all 0.3s ease"});
    }
}

//add style attribute with background image when lazyload event fired
document.addEventListener('lazybeforeunveil', function(e){
    var container = e.target;
    var bg = container.getAttribute('data-bgset');
    container.setAttribute('style', bg);
    container.removeAttribute('data-bgset');
});

function setupSlideMenu(){
    $('#mobile-menu, .slide-menu').click(function(){
        $("body").toggleClass('slide-menu-active');
    });
    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });
}

//contact page slide
var $contact = $('#contact');
var $next = $("#contact .arrow.next");
var $prev = $("#contact .arrow.prev");

$next.click(function(e){
    $contact.css('left', '-80vw');
    $next.css('display', 'none');
    $prev.css('display', 'block');
});
$prev.click(function(e){
    $contact.css('left', '0vw');
    $prev.css('display', 'none');
    $next.css('display', 'block');
});

// add prev and next arrows
function checkSlidePages($section) {
    $($section).append('<div class="prev arrow horizontal hidden"></div><div class="next arrow horizontal"></div>');
    slidePage($section);
};

//move left or right 100vw
function slidePage($section) {
    var $slider = $section.find('.slider');
    $slider[0].style.left = '0vw';
    var $prev = $section.find('.prev');
    var $next = $section.find('.next');
    var slides = $slider.find('.page-container').length;
    $next.click(function(){
        //if ($slider.data('animating')) return;
        //$slider.data('animating', true);
        var left = parseInt($slider[0].style.left);
        left = left  - 100;
        if (left / -100 >= slides - 1){
            $next.addClass('hidden');
        }
        $slider.css({"left": left + 'vw'})
        $prev.removeClass('hidden');
    });
    $prev.click(function(){
        //if ($slider.data('animating')) return;
        //$slider.data('animating', true);
        var left = parseInt($slider[0].style.left);
        left = left + 100;
        if (left == 0) {
          $prev.addClass('hidden');
        }
        $slider.css({"left": left + 'vw'})
        $next.removeClass('hidden');
    });
}

//scroll snap
var current_section = -1;
var $sections = $("section");
var animating = false;
var animateTimeout;

function menuTextColor() {
    var numberOfSections = $sections.length - 1;
    var menuText = $('.menu');
    if (current_section == numberOfSections) {
        menuText.css({'color':'#FFF', 'transition':'all 1s ease'});
    } else {
        menuText.css({'color':'#3BBFAD', 'transition':'all 1s ease'});
    };
}

function setAnimating(){
    animating = true;
    animateTimeout = setTimeout(function(){
        animating = false;
    },900)
}

function onTransitionEnd(e){
    animating = false;
    clearTimeout(animateTimeout);
}

function moveUp() {
    if (animating) return;
    setAnimating();
    scrollToIndex(current_section - 1);
}

function moveDown() {
    if (animating) return;
    setAnimating();
    scrollToIndex(current_section + 1);
}

function scrollToIndex(index) {
    var index = Math.min(index, $sections.length - 1);
    index = Math.max(index, 0);
    function doScroll() {
        current_section = index;
        var currentSection = $sections.eq(current_section);
        scrollToSection(currentSection);
    }
    doScroll();
}

function scrollToSection(section) {
    var offset = section.offset().top;
    offset = offset + $(".site-body").scrollTop();
    $(".site-body").animate({"scrollTop": offset}, 700);
    headerLogo();
    menuTextColor();
}

scrollToIndex(current_section);

// Slide on key events
window.addEventListener('keydown', function(event){
    switch(event.keyCode) {
        case 38: //Up arrow
        case 33: // page up
            moveUp();
            break;
        case 40:// down arrow
        case 34:// page down
            moveDown();
            break;
    }
}, true)

//Slide on mousewheel
window.addEventListener('wheel', onWheelScroll);

function onWheelScroll (e) {
    for (var target = e.target; target && target.matches; target = target.parentNode) {
        if (target && target.matches('.page-body')) {
            return;
        }
    }
    if (event.deltaY <0) {
        moveUp();
    } else {
        moveDown();
    }
}
$(window).on('swipeup', moveDown);
$(window).on('swipedown', moveUp);
var resizeTimer= null;
$(window).on('resize', function(){
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function(){
         scrollToIndex(current_section);
         },200);
});
window.scrollToIndex = scrollToIndex;

// hide logo on splash page
function mobileHeaderLogo() {
    var splashHeight = $('section#home').outerHeight();
    $(document).scroll(function(){
        if ($(window).scrollTop() > splashHeight){
            $("a.logo").css({"opacity": "1", "transition":"opacity 0.1s ease"});
        } else {
            $("a.logo").css({"opacity": "0", "transition":"opacity 0.1s ease"});
        }
    });
}

//disable scroll snap on mobile devices/devices with swipe as primary input
function mediaqueryresponse(mqList){
    if (mqList.matches){ // if media query matches
        $(window).off('swipeup', moveDown);
        $(window).off('swipedown', moveUp);
    }
}

var mqList = window.matchMedia("screen and (max-width: 1024px) and ((pointer: coarse), (hover: none))")
mediaqueryresponse(mqList) // call listener function explicitly at run time
mqList.addListener(mediaqueryresponse) // attach listener function to listen in on state changes

//navigate to page from click in menu or content index
$(".slide-menu a, .page-body a, .page-heading a").click(function(e){
    // prevent url clicked from opening
    e.preventDefault();
    // get href of clicked menu item
    var element_id = $(this).attr('href');
    // this is the class of the page-container (or section if no page container) selected in menu
    var containerClass = element_id.replace("/",".");
    // select the page-container with matching class
    var pageContainer = document.querySelector(containerClass + '.page-container');
    //set initial values
    var section = null;
    var index = 0;
    var slider = null;
    // only sections with slider have page containers, need alternative action for sections without page-containers
    if (pageContainer) {
        section = pageContainer.closest('section');
        slider = section.querySelector('.slider');
        // get the index position of the page container within the selected slider
        index = Array.prototype.indexOf.call(slider.children, pageContainer)
    } else {
        // find section by class, as there is no slider and therefore no page container class
        section = document.querySelector(containerClass);
        slider = section.querySelector('.slider');
    }
    // scroll the section vertically into viewport
    section.scrollIntoView({behavior: "smooth"});
    //Need to update the current_section
    var main = document.querySelector('main');
    var section_index = Array.prototype.indexOf.call(main.children, section);
    current_section = section_index;
    headerLogo();
    menuTextColor()
    // scroll the page container horizontally into viewport
    slider.style.left = (index * -100) + 'vw';
    var prev = section.querySelector('.prev');
    var next = section.querySelector('.next');
    var slides = slider.querySelectorAll('.page-container').length;
    if (index == 0) {
        prev.classList.add('hidden');
        next.classList.remove('hidden');
    } else if (index == slides - 1) {
        next.classList.add('hidden');
        prev.classList.remove('hidden');
    } else {
        next.classList.remove('hidden');
        prev.classList.remove('hidden');
    }
});